<template>
  <div class="main_container">
    <!-- Ligne n°1 -->
    <div class="row mb-3 bread-crumbs">
      <div class="text-left col-12">
        <span>Rapports > Détails Trafic Péage</span>
      </div>
    </div>
    
    <div class="row justify-content-center my-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails sur le traffic de péage
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
    </div>
    <vue-html2pdf :show-layout="false" 
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  filename="Detail_trafic"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf">
      <section slot="pdf-content"> 
        <div class="row justify-content-center my-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Détails sur le traffic de péage
              </div>
            </div>
          </div>
        </div>
        <div class="row"
             style="padding-left:50px;padding-right:50px;">
          <table class="table table-striped table-blue">
            <thead>
              <tr>
                <th style="border-top:none"></th>
                <th colspan="7" 
                    class="text-center tht-blue">{{ gareA }}</th>
              </tr>
            </thead>
            <tbody v-if="gareDataA != null">
              <tr class="text-center">
                <td>Mois</td>
                <td>classe 1</td>
                <td>classe 2</td>
                <td>classe 3</td>
                <td>classe 4</td>
                <td>classe 5</td>
                <td class="tht-blue">SOUS-TOTAL</td>
              </tr>
            
              <tr v-for="(mois, mkey) in month" 
                  :key="mkey">
                <td>{{ mois.mois }}</td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataA.classe_1[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataA.classe_2[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataA.classe_3[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataA.classe_4[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataA.classe_5[mkey])).toLocaleString() }}
                </td>
                <td class="text-right tht-blue">
                  {{ Math.round(Number(mois.total)).toLocaleString() }}
                </td>
              </tr>
              <tr class="tr-blue">
                <td>TOTAUX</td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataA.totaux.classe_1)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataA.totaux.classe_2)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataA.totaux.classe_3)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataA.totaux.classe_4)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataA.totaux.classe_5)).toLocaleString()
                  }}
                </td>
                <td class="tht-blue text-right">
                  {{ Math.round(Number(monthTotal)).toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
  
          <table class="table table-striped table-blue">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th colspan="7" 
                    class="text-center tht-blue">{{ gareS }}</th>
              </tr>
            </thead>
            <tbody v-if="gareDataS != null">
              <tr class="text-center">
                <td>Mois</td>
                <td>classe 1</td>
                <td>classe 2</td>
                <td>classe 3</td>
                <td>classe 4</td>
                <td>classe 5</td>
                <td class="tht-blue">SOUS-TOTAL</td>
              </tr>
  
              <tr v-for="(mois, mkey) in month" 
                  :key="mkey">
                <td>{{ mois.mois }}</td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataS.classe_1[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataS.classe_2[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataS.classe_3[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataS.classe_4[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataS.classe_5[mkey])).toLocaleString() }}
                </td>
                <td class="text-right tht-blue">
                  {{ Math.round(Number(mois.total)).toLocaleString() }}
                </td>
              </tr>
              <tr class="tr-blue">
                <td>TOTAUX</td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataS.totaux.classe_1)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataS.totaux.classe_2)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataS.totaux.classe_3)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataS.totaux.classe_4)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataS.totaux.classe_5)).toLocaleString()
                  }}
                </td>
                <td class="tht-blue text-right">
                  {{ Math.round(Number(monthTotal)).toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
  
          <table class="table table-striped table-blue">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th colspan="7" 
                    class="text-center tht-blue">{{ gareT }}</th>
              </tr>
            </thead>
            <tbody v-if="gareDataT != null">
              <tr class="text-center">
                <td>Mois</td>
                <td>classe 1</td>
                <td>classe 2</td>
                <td>classe 3</td>
                <td>classe 4</td>
                <td>classe 5</td>
                <td class="tht-blue">SOUS-TOTAL</td>
              </tr>
              <tr v-for="(mois, mkey) in month" 
                  :key="mkey">
                <td>{{ mois.mois }}</td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataT.classe_1[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataT.classe_2[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataT.classe_3[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataT.classe_4[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataT.classe_5[mkey])).toLocaleString() }}
                </td>
                <td class="text-right tht-blue">
                  {{ Math.round(Number(mois.total)).toLocaleString() }}
                </td>
              </tr>
              <tr class="tr-blue">
                <td>TOTAUX</td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataT.totaux.classe_1)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataT.totaux.classe_2)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataT.totaux.classe_3)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataT.totaux.classe_4)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataT.totaux.classe_5)).toLocaleString()
                  }}
                </td>
                <td class="tht-blue text-right">
                  {{ Math.round(Number(monthTotal)).toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
  
          <table class="table table-striped table-blue">
            <thead>
              <tr>
                <th class="text-center"></th>
                <th colspan="7" 
                    class="text-center tht-blue">{{ gareM }}</th>
              </tr>
            </thead>
            <tbody v-if="gareDataM != null">
              <tr class="text-center">
                <td>Mois</td>
                <td>classe 1</td>
                <td>classe 2</td>
                <td>classe 3</td>
                <td>classe 4</td>
                <td>classe 5</td>
                <td class="tht-blue">SOUS-TOTAL</td>
              </tr>
              <tr v-for="(mois, mkey) in month" 
                  :key="mkey">
                <td>{{ mois.mois }}</td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataM.classe_1[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataM.classe_2[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataM.classe_3[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataM.classe_4[mkey])).toLocaleString() }}
                </td>
                <td class="text-right">
                  {{ Math.round(Number(gareDataM.classe_5[mkey])).toLocaleString() }}
                </td>
                <td class="text-right tht-blue">
                  {{ Math.round(Number(mois.total)).toLocaleString() }}
                </td>
              </tr>
              <tr class="tr-blue">
                <td>TOTAUX</td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataM.totaux.classe_1)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataM.totaux.classe_2)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataM.totaux.classe_3)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataM.totaux.classe_4)).toLocaleString()
                  }}
                </td>
                <td class="text-right">
                  {{
                    Math.round(Number(gareDataM.totaux.classe_5)).toLocaleString()
                  }}
                </td>
                <td class="tht-blue text-right">
                  {{ Math.round(Number(monthTotal)).toLocaleString() }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </vue-html2pdf>
    <div class="row">
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Période du: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_debut"/>
        </div>
        <div class="form-group">
          <label for="">au: </label>
          <input class="form-control mx-2" 
                 name="" 
                 id=""
                 type="date"
                 v-model="annee_fin"/>
        </div>
        <div class="form-group">
          <button type="button"
                  class="btn btn-info"
                  @click="changeYear()">
            Rechercher
          </button>
        </div>
      </form>
    </div>
    <!-- Début tableau Attingue -->
    <div class="row mt-4">
      <table class="table table-striped table-blue">
        <thead>
          <tr>
            <th class="text-center"></th>
            <th colspan="7" 
                class="text-center tht-blue">{{ gareA }}</th>
          </tr>
        </thead>
        <tbody v-if="gareDataA != null">
          <tr class="text-center">
            <td>Mois</td>
            <td>classe 1</td>
            <td>classe 2</td>
            <td>classe 3</td>
            <td>classe 4</td>
            <td>classe 5</td>
            <td class="tht-blue">SOUS-TOTAL</td>
          </tr>

          <tr v-for="(mois, mkey) in month" 
              :key="mkey">
            <td>{{ mois.mois }}</td>
            <td class="text-right">
              {{ Math.round(Number(gareDataA.classe_1[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataA.classe_2[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataA.classe_3[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataA.classe_4[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataA.classe_5[mkey])).toLocaleString() }}
            </td>
            <td class="text-right tht-blue">
              {{ Math.round(Number(mois.total)).toLocaleString() }}
            </td>
          </tr>
          <tr class="tr-blue">
            <td>TOTAUX</td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataA.totaux.classe_1)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataA.totaux.classe_2)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataA.totaux.classe_3)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataA.totaux.classe_4)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataA.totaux.classe_5)).toLocaleString()
              }}
            </td>
            <td class="tht-blue text-right">
              {{ Math.round(Number(monthTotal)).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Fin tableau Attingue -->

    <!-- Début tableau Singrobo -->
    <div class="row">
      <table class="table table-striped table-blue">
        <thead>
          <tr>
            <th class="text-center"></th>
            <th colspan="7" 
                class="text-center tht-blue">{{ gareS }}</th>
          </tr>
        </thead>
        <tbody v-if="gareDataS != null">
          <tr class="text-center">
            <td>Mois</td>
            <td>classe 1</td>
            <td>classe 2</td>
            <td>classe 3</td>
            <td>classe 4</td>
            <td>classe 5</td>
            <td class="tht-blue">SOUS-TOTAL</td>
          </tr>

          <tr v-for="(mois, mkey) in month" 
              :key="mkey">
            <td>{{ mois.mois }}</td>
            <td class="text-right">
              {{ Math.round(Number(gareDataS.classe_1[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataS.classe_2[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataS.classe_3[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataS.classe_4[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataS.classe_5[mkey])).toLocaleString() }}
            </td>
            <td class="text-right tht-blue">
              {{ Math.round(Number(mois.total)).toLocaleString() }}
            </td>
          </tr>
          <tr class="tr-blue">
            <td>TOTAUX</td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataS.totaux.classe_1)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataS.totaux.classe_2)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataS.totaux.classe_3)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataS.totaux.classe_4)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataS.totaux.classe_5)).toLocaleString()
              }}
            </td>
            <td class="tht-blue text-right">
              {{ Math.round(Number(monthTotal)).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Fin tableau Singrobo -->

    <!-- Début tableau Thomasset -->
    <div class="row">
      <table class="table table-striped table-blue">
        <thead>
          <tr>
            <th class="text-center"></th>
            <th colspan="7" 
                class="text-center tht-blue">{{ gareT }}</th>
          </tr>
        </thead>
        <tbody v-if="gareDataT != null">
          <tr class="text-center">
            <td>Mois</td>
            <td>classe 1</td>
            <td>classe 2</td>
            <td>classe 3</td>
            <td>classe 4</td>
            <td>classe 5</td>
            <td class="tht-blue">SOUS-TOTAL</td>
          </tr>

          <tr v-for="(mois, mkey) in month" 
              :key="mkey">
            <td>{{ mois.mois }}</td>
            <td class="text-right">
              {{ Math.round(Number(gareDataT.classe_1[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataT.classe_2[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataT.classe_3[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataT.classe_4[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataT.classe_5[mkey])).toLocaleString() }}
            </td>
            <td class="text-right tht-blue">
              {{ Math.round(Number(mois.total)).toLocaleString() }}
            </td>
          </tr>
          <tr class="tr-blue">
            <td>TOTAUX</td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataT.totaux.classe_1)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataT.totaux.classe_2)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataT.totaux.classe_3)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataT.totaux.classe_4)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataT.totaux.classe_5)).toLocaleString()
              }}
            </td>
            <td class="tht-blue text-right">
              {{ Math.round(Number(monthTotal)).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Fin tableau Thomasset -->

    <!-- Début tableau Moape -->
    <div class="row">
      <table class="table table-striped table-blue">
        <thead>
          <tr>
            <th class="text-center"></th>
            <th colspan="7" 
                class="text-center tht-blue">{{ gareM }}</th>
          </tr>
        </thead>
        <tbody v-if="gareDataM != null">
          <tr class="text-center">
            <td>Mois</td>
            <td>classe 1</td>
            <td>classe 2</td>
            <td>classe 3</td>
            <td>classe 4</td>
            <td>classe 5</td>
            <td class="tht-blue">SOUS-TOTAL</td>
          </tr>

          <tr v-for="(mois, mkey) in month" 
              :key="mkey">
            <td>{{ mois.mois }}</td>
            <td class="text-right">
              {{ Math.round(Number(gareDataM.classe_1[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataM.classe_2[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataM.classe_3[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataM.classe_4[mkey])).toLocaleString() }}
            </td>
            <td class="text-right">
              {{ Math.round(Number(gareDataM.classe_5[mkey])).toLocaleString() }}
            </td>
            <td class="text-right tht-blue">
              {{ Math.round(Number(mois.total)).toLocaleString() }}
            </td>
          </tr>
          <tr class="tr-blue">
            <td>TOTAUX</td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataM.totaux.classe_1)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataM.totaux.classe_2)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataM.totaux.classe_3)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataM.totaux.classe_4)).toLocaleString()
              }}
            </td>
            <td class="text-right">
              {{
                Math.round(Number(gareDataM.totaux.classe_5)).toLocaleString()
              }}
            </td>
            <td class="tht-blue text-right">
              {{ Math.round(Number(monthTotal)).toLocaleString() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Fin tableau Moape -->
    <div class="row"
         v-if="loaded==true">
      <div style="width: 800px; margin: auto">
        <BarChart :chartdata="randomChart1"
                  :options="optionsChart1" />
      </div>
    </div>
  </div>
</template>
<style>
form input[type="text"], 
form input[type="date"], 
form input[type="number"], 
form input[type="file"], 
form textarea,
form select option,
form select.form-control,
.btn-info,
span[slot="no-options"].text-danger{
  font-size: 0.6em;
}
form select.form-control option{
  font-size: 1em;
}
input.vs__search,.table-blue{
  font-size: 0.7em;
}
form.form_default input[type="text"], 
form.form_default input[type="date"], 
form.form_default input[type="number"], 
form.form_default input[type="file"], 
form.form_default textarea{
  height: fit-content;
}
/* div.vs__dropdown-toggle,form select.form-control{
  height: 3.5vh;
} */

@media only screen and (width > 1466px){
  div.vs__dropdown-toggle,form select.form-control{
  height: 2.5vh;
  }
}
/* form.form-inline input,
form.form-inline select, */
li.nav-item
/* form.form-inline button */{
  font-size: 0.6em;
}
</style>
<script>
// import Pie from "./PieChart"
import { mapActions, mapMutations, mapGetters } from "vuex"
import VueHtml2pdf from 'vue-html2pdf'
import BarChart from "@/components/shared/BarChart"
export default {
  name: "RctPeage",
  components: {
    VueHtml2pdf,
    BarChart
  },
  
  data: () => ({
    chartData: {
      labels: [],
      datasets: [
        {
          label: "Recette péage (%)",
          data: [],
          backgroundColor: [],
          hoverOffset: 4,
        },
      ],
    },
    width: "col-md-5",
    annee_debut: new Date().getFullYear()+"-01-01",
    annee_fin: new Date().getFullYear()+"-12-31",
    year:{
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
    },
    yearData: null,
    gareA: "ATTINGUE",
    gareS: "SINGROBO",
    gareT: "THOMASSET",
    gareM: "MOAPE",
    month: [
      { mois: "Janv.", total: 0 },
      { mois: "Fev.", total: 0 },
      { mois: "Mars", total: 0 },
      { mois: "Avr.", total: 0 },
      { mois: "Mai.", total: 0 },
      { mois: "Juin", total: 0 },
      { mois: "Juil.", total: 0 },
      { mois: "Août.", total: 0 },
      { mois: "Sept.", total: 0 },
      { mois: "Oct.", total: 0 },
      { mois: "Nov.", total: 0 },
      { mois: "Dec.", total: 0 },
    ],
    monthTotal: 0,
    gareData: null,
    gareDataA: null,
    gareDataS: null,
    gareDataT: null,
    gareDataM: null,
    total: 0,
    tableauCouleurs: ["#0097A9", "#016677", "#00B5CB", "#00383F", "#01606B"],
    loaded:false,
  }),
  created() {
    // this.RecettePeage({ annee: this.year })
    // this.RecettePeageG({ annee: this.year, gare: this.gare })

    this.RecettePeageAttingue({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gareA: this.gareA})

    this.RecettePeageSingrobo({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gare: this.gare})

    this.RecettePeageThomasset({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gare: this.gare })

    this.RecettePeageMoape({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gare: this.gare })

    if (window.innerWidth > 1400) {
      this.width = "col-md-4 mx-auto"
    }
  },
  watch: {
    // arRecetteG() {
    //   this.yearData = this.arRecetteG
    //   this.addColor(this.yearData.recettes.length)

    //   if (this.yearData.recettes.length > 0){
    //     for (var index = 0; index < this.yearData.recettes.length; index++) {
    //       this.chartData.datasets[0].data.push(Math.round(Number(this.yearData.recettes[index].pourcentage) * 100) / 100)
    //       this.chartData.labels.push(this.yearData.recettes[index].gare + " (" + Math.round(Number(this.yearData.recettes[index].pourcentage) * 100) / 100 + "%)")
    //     }
    //   }else{
    //     this.chartData.labels=[]
    //     this.chartData.datasets[0].data=[]
    //     this.chartData.datasets[0].backgroundColor=[]
    //   }
    // },
    // arRecette() {
    //   this.gareData = this.arRecette
    //   for (var index = 1; index < 13; index++) {
    //     if (Object.prototype.hasOwnProperty.call(this.gareData.recettes, "0"+index)) {
    //       this.month.push(this.gareData.recettes['0'+index])
    //     }
    //   }
    // },
    recettePeageG() {
      if (this.recettePeageG["tableau"]) {
        if (this.recettePeageG["tableau"][0]) {
          this.gareData = this.recettePeageG["tableau"][0]
          this.monthTotal = 0
          for (let index = 0; index < this.month.length; index++) {
            this.month[index].total =
              this.gareData.classe_1[index] +
              this.gareData.classe_2[index] +
              this.gareData.classe_3[index] +
              this.gareData.classe_4[index] +
              this.gareData.classe_5[index]
            this.monthTotal += this.month[index].total
          }
          console.log(this.gareData)
        } else {
          this.gareData = this.recettePeageG["tableau"]
        }
      }
    },

    recettePeageA() {
      if (this.recettePeageA["tableau"]) {
        if (this.recettePeageA["tableau"][0]) {
          this.gareDataA = this.recettePeageA["tableau"][0]
          this.monthTotal = 0
          for (let index = 0; index < this.month.length; index++) {
            this.month[index].total =
              this.gareDataA.classe_1[index] +
              this.gareDataA.classe_2[index] +
              this.gareDataA.classe_3[index] +
              this.gareDataA.classe_4[index] +
              this.gareDataA.classe_5[index]
            this.monthTotal += this.month[index].total
          }
          console.log(this.gareDataA)
        } else {
          this.gareDataA = this.recettePeageA["tableau"]
        }
      }
    },
    
    recettePeageS() {
      if (this.recettePeageS["tableau"]) {
        if (this.recettePeageS["tableau"][0]) {
          this.gareDataS = this.recettePeageS["tableau"][0]
          this.monthTotal = 0
          for (let index = 0; index < this.month.length; index++) {
            this.month[index].total =
              this.gareDataS.classe_1[index] +
              this.gareDataS.classe_2[index] +
              this.gareDataS.classe_3[index] +
              this.gareDataS.classe_4[index] +
              this.gareDataS.classe_5[index]
            this.monthTotal += this.month[index].total
          }
          console.log(this.gareDataS)
        } else {
          this.gareDataS = this.recettePeageS["tableau"]
        }
      }
    },

    recettePeageT() {
      if (this.recettePeageT["tableau"]) {
        if (this.recettePeageT["tableau"][0]) {
          this.gareDataT = this.recettePeageT["tableau"][0]
          this.monthTotal = 0
          for (let index = 0; index < this.month.length; index++) {
            this.month[index].total =
              this.gareDataT.classe_1[index] +
              this.gareDataT.classe_2[index] +
              this.gareDataT.classe_3[index] +
              this.gareDataT.classe_4[index] +
              this.gareDataT.classe_5[index]
            this.monthTotal += this.month[index].total
          }
          console.log(this.gareDataT)
        } else {
          this.gareDataT = this.recettePeageT["tableau"]
        }
      }
    },

    recettePeageM() {
      if (this.recettePeageM["tableau"]) {
        if (this.recettePeageM["tableau"][0]) {
          this.gareDataM = this.recettePeageM["tableau"][0]
          this.monthTotal = 0
          for (let index = 0; index < this.month.length; index++) {
            this.month[index].total =
              this.gareDataM.classe_1[index] +
              this.gareDataM.classe_2[index] +
              this.gareDataM.classe_3[index] +
              this.gareDataM.classe_4[index] +
              this.gareDataM.classe_5[index]
            this.monthTotal += this.month[index].total
          }
          console.log(this.gareDataM)
        } else {
          this.gareDataM = this.recettePeageM["tableau"]
        }
      }
    },

    recettePeage() {
      if (this.recettePeage["tableau"]) {
        this.yearData = this.recettePeage
        this.total = 0
        this.yearData.tableau.forEach((recette) => {
          this.total += Number(recette.recettes)
        })
        if (window.innerWidth > 1400) {
          this.width = "col-md-4 mx-auto"
        }
        this.addColor(this.yearData.graphique.length)

        this.yearData.graphique.forEach((graphique) => {
          this.chartData.datasets[0].data.push(
            Math.round(Number(graphique.pourcentage) * 100) / 100
          )
          this.chartData.labels.push(
            graphique.gare +
              " (" +
              Math.round(Number(graphique.pourcentage) * 100) / 100 +
              "%)"
          )
        })
      }
    },
    extYear() {
      if (this.extYear) {
        this.setRecettePeage("")
        
        this.year = this.extYear
        this.yearData = null
        this.gareDataA = null
        this.gareDataS = null
        this.gareDataT = null
        this.gareDataM = null
        this.RecettePeage({ annee: this.year })


        this.chartData.datasets[0].data = []
        this.chartData.labels = []
        this.chartData.datasets[0].backgroundColor = []
      }
    },
  },
  computed: {
    ...mapGetters(["recettePeageA", "recettePeageS",  "recettePeageT","recettePeageM"]),
  },
  methods: {
    ...mapActions(["RecettePeageAttingue", "RecettePeageSingrobo", "RecettePeageThomasset","RecettePeageMoape"]),

    ...mapMutations(["setRecettePeageA", "setRecettePeageS", "setRecettePeageT", "setRecettePeageM"]),
   
    changeGare() {
      this.gareData = null
      // this.RecettePeage({ annee: this.year })
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin

      this.RecettePeageG({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin, gare: this.gare })
      this.RecettePeageAttingue({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin })
      this.RecettePeageSingrobo({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin })
      this.RecettePeageThomasset({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin })
      this.RecettePeageMoape({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin })
    },
    changeYear() {
      this.year.annee_debut= this.annee_debut
      this.year.annee_fin= this.annee_fin

      this.RecettePeageAttingue({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin })
      this.RecettePeageSingrobo({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin})
      this.RecettePeageThomasset({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin})
      this.RecettePeageMoape({ annee_debut: this.year.annee_debut, annee_fin: this.year.annee_fin})
      
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
    addColor(long) {
      for (let i = 0; i < long; i++) {
        this.chartData.datasets[0].backgroundColor.push(
          this.tableauCouleurs[i]
        )
      }
    },
  },
}
</script>
  